import merchantService from '@/services/merchant'

export default {

    computed: {

        merchant() {

            return this.$store.getters.merchant
        },
        merchantId() {

            if (this.merchant == undefined) return undefined

            return this.$store.getters.merchant.id
        },
        subdomain() {

            return window.location.hostname.split('.')[0]
        },
        domain() {

            return window.location.hostname.substring(
                window.location.hostname.indexOf('.') + 1
            )
        },
        merchantCss() {

            if (this.subdomain.includes('darty-assurance')) {
                
                return 'darty-assurance';
            }
            
            if (this.subdomain.includes('assurantprivilegepass')) {
            
                return 'assurantprivilegepass'
            }
            
            return 'default'
        }
    },
    methods: {

        async loadMerchant(merchantId) {
            
            if (this.merchant == undefined || this.merchant.id != merchantId) {

                let response = await merchantService.getMerchant(merchantId)
                let merchant = response.data
                this.$store.commit('setMerchant', merchant)
            }
            
            if (this.merchant != undefined) {

                let subdomain = window.location.hostname.split('.')[0]
                if (subdomain != this.merchant.subdomain) {

                    window.location = window.location.href.replace('://'+subdomain+'.', '://'+this.merchant.subdomain+'.')
                }
            }
        },
        async loadMerchantBySubdomain() {

            if (this.merchant == undefined) {

                let subdomain = this.subdomain
                let domain = this.domain

                if (domain.includes('greendid')) {
                    
                    if (subdomain.includes('leclercroques') == false) {
 
                        domain = null
                    }
                } 
                
                let response = await merchantService.getMerchantBySubdomain(subdomain, domain)
                let merchant = response.data
                
                if (this.isCurrentUserAllowed(merchant)) {

                    this.$store.commit('setMerchant', merchant)
                }
                else {

                    this.$store.dispatch('signOut')
                    this.$router.push({name: 'signIn'})
                }
            }
        },
        isCurrentUserAllowed(merchant) {

            let user = this.$store.getters.user

            if (user == undefined) return true
        
            if (merchant.attach_users == true) {

                if (merchant.id == user.merchant_id) return true
            }
            else {

                if (user.merchant_id == 0) return true
            }

            return false
        }
    }
}
