<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '../../mixins/order'
import merchantMixin from '@/mixins/merchant'
import orderService from '../../services/order'
import kycService from '../../services/kyc'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import StandardModal from '../../components/StandardModalV2.vue'
import CancelOrderButton from '@/components/basket/CancelOrderButton.vue';

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ProductCaption, StandardModal, CancelOrderButton},
    mixins: [orderMixin, merchantMixin],
    data() {
        return {

            files:[],
            identityType: '',
            country: 'FR',
            isUploadLoading: false,
            askForUpdateLater: false,
            askForUpdateLaterModal: false,
            error: '',
            isLoading: false,
            modalCancelOrder: false
        }
    },
    created() {
      
        this.$store.commit('setCurrentStep', 40)
    },
    computed: {
        isMobile() {
            
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === true
        }
    },
    methods:{
        addFile(e) {

            let droppedFiles = e.dataTransfer != undefined ? e.dataTransfer.files : e.target.files

            if (!droppedFiles) return
            ([...droppedFiles]).forEach(f => {
                this.files.push(f)
            });
        },
        removeFile(file) {

            this.files = this.files.filter(f => {
                return f != file
            })
        },
        async upload() {

            this.error = ''
            this.isLoading = true
            if (!this.askForUpdateLater && this.files.length == 0) {

                this.error = 'Veuillez choisir un type de pièce d\'identité'
            }
            else if (this.askForUpdateLater && this.files.length == 0) {

                this.askUploadLaterModal()
            }
            else {

                let file1 = this.files[0]
                let file2 = null
                if (this.files.length > 1) 
                    file2 = this.files[1]

                this.isUploadLoading = true
                try {
                   
                    await kycService.uploadKyc(this.identityType, file1, file2)
                }
                catch (error) {

                    this.$tracking.logEvent('KYC upload error', {'Error': error})
                }
                this.isUploadLoading = false

                this.$router.push({name: 'orderTransfer', params: {id: this.order.public_id}, query: {f: 1}})
            }

            this.isLoading = false
        },
        uploadLater() {

            this.askForUpdateLaterModal = false
            this.$tracking.logEvent('Upload KYC later', {'Order ID': this.orderId})
            this.$router.push({name: 'orderTransfer', params: {id: this.order.public_id}, query: {f: 1}})
        },
        askUploadLater() {
            
            if (this.askForUpdateLater == false) {

                this.askForUpdateLater = true
            }
            else {

                this.askForUpdateLater = false
            }
        },
        askUploadLaterModal() {

            this.askForUpdateLaterModal = true
        },
        async cancelOrder() {

            await orderService.cancelOrderById(this.orderId)
            
            this.$router.push({name: 'searchForm'})
        },
        closeModalCancelOrder() {

            this.modalCancelOrder = false

            this.$tracking.logEvent('Cancel order closed', {'Order ID': this.orderId})
        },
    },
    watch: {
        identityType() {

            if (this.identityType != '') {

                this.askForUpdateLater = false;
            }
        },
        askForUpdateLater() {

            if (this.askForUpdateLater == true) {
                this.identityType = ''
            }
        },
        isOrderLoaded() {

            this.$tracking.pageView('kyc', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<style scoped>
    .btn-outline-danger {
        background-color: white !important;
    }
    .btn-outline-danger:hover {
        background-color: #f34770 !important;
    }
    #selectCard:hover {
        border-color: #21d5b6 !important;
    }
</style>
<template>
    <basket-layout>
        <div v-if="this.order">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11">
                        <success-alert :message="'Votre empreinte de CB a bien été validée !'" v-if="$route.query.pv == 1" />
                        <h3>Ma pièce d'identité</h3>
                        <span>Merci de fournir une pièce d'identité en cours de validité, c’est une obligation légale afin de valider la vente.</span>
                        <div class="mx-0 row">
                            <div class="mt-4 px-0 col-md-5 col-12">
                                <div class="card shadow-lg h-100" id="selectCard" :class="{'border-success-lg border-lg': identityType == 'identity_card', '': identityType == 'passport' || identityType == ''}" role="button" @click="identityType = 'identity_card'">
                                    <div class="card-body pt-2 pb-0 pe-3">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <p class="my-2">CARTE D'IDENTITÉ</p>
                                        </div>
                                        <div class="row mt-2 mb-4">
                                            <div class="col-1 d-flex justify-content-center align-items-center px-0 pb-4">
                                                <input class="form-check-input" type="radio" name="identityChoice" :checked="identityType == 'identity_card'">
                                            </div>
                                            <div class="col-7 d-flex justify-content-center align-items-center pe-0 pt-1 small">
                                                <p><strong>Recto - Verso nécessaire</strong><br/>accepté en 1 ou 2 fichiers</p>
                                            </div>
                                            <div class="col d-flex justify-content-center align-items-center px-0">
                                                <img src="/assets/img/icon-id-card.png" width="65"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 px-0 offset-md-1 col-md-5 col-12">
                                <div class="card shadow-lg h-100" id="selectCard" :class="{'border-success-lg border-lg': identityType == 'passport', '': identityType == 'identity_card' || identityType == ''}" role="button" @click="identityType = 'passport'">
                                    <div class="card-body pt-2 pb-4">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <p class="my-2">PASSEPORT</p>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col-1 d-flex justify-content-center align-items-center px-0 pb-4">
                                                <input id="passportInput" class="form-check-input" type="radio" name="identityChoice" :checked="identityType == 'passport'">
                                            </div>
                                            <div class="col-7 d-flex justify-content-center align-items-center pe-0 small">
                                                <p><strong>Page avec la photo</strong></p>
                                            </div>
                                            <div class="col d-flex justify-content-center align-items-center px-0 ">
                                                <img src="/assets/img/icon-passport.png" width="55"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="identityType != ''" class=" col-md-7 mt-4">
                            <div v-if="!isMobile" style="min-height:100px" class="form-group border  p-2 bg-white" v-cloak @drop.prevent="addFile" @dragover.prevent>
                                <label>Faites glisser vos fichiers ici :</label>
                                <br/>
                                <span v-for="(file, index) in files" :key="index">
                                    <div class="form-group"><button class="btn text-danger btn-sm" @click="removeFile(file)" title="Remove"><strong>X</strong></button> {{ file.name }} ({{ (file.size / 1024 / 1000).toFixed(1) }} Mo)</div>
                                </span>
                            </div>
                            <div class="form-group border p-2 bg-white">
                                <label v-if="isMobile">Ajoutez vos fichiers ici : </label>
                                <label v-if="!isMobile">Ou ajoutez vos fichiers ici : </label>
                                <input id="file" multiple type="file" @change="addFile" accept=".png, .jpg, .jpeg, .pdf">
                            </div>
                        </div>
                        <div v-if="error == 'Veuillez choisir un type de pièce d\'identité'" class="mt-4 alert alert-danger">
                            <span>Merci de fournir une pièce d'identité ou de cocher la case ci-dessous.</span>
                        </div>
                        <button class="btn btn-success shadow-lg text-white text-center mt-4 col-md-5 col-12" @click="upload()">
                            Continuer
                             <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        </button>
                        <div class="form-check my-4">
                            <input type="checkbox" class="form-check-input rounded-0" v-model="askForUpdateLater" :disabled="files.length > 0">
                            <label class="form-check-label" @click="identityType == '' ? askForUpdateLater = !askForUpdateLater : {}" :disabled="files.length > 0">Je n’ai pas ma pièce d’identité à disposition, je la transmettrai plus tard par mail : contact-darty@greendid.com .</label>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderTotalPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" :contributionPrice="order.contribution_amount" />
                        <cancel-order-button v-if="order.active_counter_offer === null && order.accepted_counter_offer === null" v-model="modalCancelOrder" @click="modalCancelOrder = true"/>
                    </div>
                    <product-caption/>
                </div>
            </div>
        </div>
        <standard-modal v-if="askForUpdateLaterModal" :closeClass="'btn-success'" :closeLabel="'Importer ma pièce d\'identité'" @close="askForUpdateLaterModal = false">
            <template v-slot:body>
                <div>
                    <p>Vous devez fournir une pièce d’identité valide pour obtenir votre carte cadeau</p>
                </div>
                <div class="row d-flex justify-content-center mt-4">
                    <button role="button" class="btn btn-success col-7" @click="askForUpdateLaterModal = false">Importer ma <br class="d-md-none">pièce d'identité</button>
                    <button role="button" class="btn btn-outline-danger offset-1 col-4 px-0" @click="{uploadLater()}">Continuer</button>
                </div>
            </template>
        </standard-modal>
        <standard-modal v-if="modalCancelOrder">
            <template v-slot:body>
                <p> Êtes vous sûr de vouloir annuler votre reprise ?</p>
                <button role="button" class="btn btn-outline-danger col-7" @click="cancelOrder()">Confirmer l'annulation</button>
                <button role="button" class="btn btn-primary offset-1 col-3 px-0"  @click="closeModalCancelOrder()"><span class="text-white">Retour</span></button>
            </template>
        </standard-modal>
    </basket-layout>
</template>
