<script type="text/ecmascript-6">

export default {
    props: ['value', 'required'],
    data() {

        return {
            autocomplete: null
        }
    }
}
</script>
<template>
    <section>
        <div class="form-group mt-3" >
            <input :required="required == undefined ? false : required" class="form-control" placeholder="Entrer votre adresse postale" type="text" v-model="value.address1" />
        </div>
        <div class="form-group row mt-3">
            <div class="col"><input :required="required == undefined ? false : required" class="form-control" placeholder="Code postal" type="text" v-model="value.zipCode" /></div>
            <div class="col"><input :required="required == undefined ? false : required" class="form-control" placeholder="Ville" type="text" v-model="value.city" /></div>
            <div class="col"><input :required="required == undefined ? false : required" class="form-control" placeholder="Pays" type="text" v-model="value.country" /></div>
        </div>
    </section>
</template>
