<script type="text/ecmascript-6">

export default {
    props: ['closeLabel', 'closeClass'],
    methods: {
        close() {

            this.$emit('close')
        },
        buttonClicked(buttonName) {

            this.$emit('buttonClicked'+buttonName)
        }
    }
}

</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal modal-wrapper" style="overflow-y: auto">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content bg-gd-bg">
                        <div class="modal-body px-5">
                            <slot name="body"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
