export default {

    methods: {

        getLocalImage(name) {

            return '/assets/img/' +  name
        },
        getMerchantAsset(merchandId, assetName) {

            return process.env.VUE_APP_ASSETS_URL + merchandId + '/' + assetName
        },
        getCategoryAsset(category) {

            return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/' + category.img_filename
        },
        getCategoryAssetGeneric(categoryId, brand) {

            if (brand === 'APPLE') {

                return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/category-' + categoryId + '-APPLE-generic.png'
            }
            
            return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/category-' + categoryId + '-generic.png'
        }
    }
}
