<script type="text/ecmascript-6">

import StandardModal from '@/components/StandardModal.vue'

export default {
    props: {
        'conditions': Object , 
        'productConditions': {
            type: Object,
            default: function() {

                return {}
            }
        }, 
        'productType': String,
        'onlyWithAlert': {
            type: Boolean,
            default: true
        }
    },
    components: {StandardModal},
    data() {

        return {

            likeNewReminder: false,
            alertCondition: null,
            helperCondition: null,
        }
    },
    methods: {

        updateCondition(conditionName, val, event) {

            let condition = this.getCondition(conditionName)
 
            if (this.canBeChecked(condition, val) == false) {
                
                this.alertCondition = condition
                event.preventDefault()

                return
            }

            this.$emit('updateCondition', {name: conditionName, val: val}, event)
        },
        getConditionLabel(val, productType) {

            let label = this.$t('BASKET_CONDITION_LABEL.' + productType + '.' + val)
            let index = label.indexOf(' - ')

            return label.substring(0, index)
        },
        getProductCondition(conditionName) {

            return this.productConditions[conditionName] != undefined ? this.productConditions[conditionName] : undefined
        },
        getCondition(conditionName) {

            return Object.values(this.conditions).find(element => element.name == conditionName)
        },
        close() {

            this.alertCondition = null
        },
        canBeChecked(condition, val) {

            return condition.alert == undefined || condition.alert != true || condition.answer == val
        },
        styleLabel(label) {

            return '<strong>' + label.replace('-', '</strong> - ')
        },
    },
}
</script>
<style scoped>
    label.-label {
        font-size: var(--bs-body-font-size) !important;
        border-width: 2px !important;
    }
    button.btn-helper {
        text-decoration: underline;
        border: 1px solid transparent;
        background-color: transparent;
        font-size: 0.8em;
    }

    .form-check {
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5em;
        margin-bottom: 0.125rem;
    }

    .btn-background {

        border: var(--bs-gray-300) 1px solid;
        background-color: var(--bs-gray-100);
    }

</style>
<template>
    <div>    
        
        <div :class="{'mt-4': index > 1}" v-for="(condition, index) in conditions" :key="condition.name">
            <div v-if="onlyWithAlert == false || condition.answer != undefined">
                <p v-html="'<span >' + $t('BASKET_CONDITION.' + condition.name + '.' + productType + '.TITLE') + '</span>'"></p>
                <div class="row" v-if="condition.type == 'boolean'">
                    <div class="col-5 h-100 ">
                        <input type="radio" class="btn-check d-none" :id="condition.name + '-yes'" :name="condition.name" :checked="getProductCondition(condition.name) === true" @click="updateCondition(condition.name, true, $event)">
                        <label :for="condition.name + '-yes'" class="btn btn-outline-primary btn-background w-100 h-100 d-flex align-items-center justify-content-center " >Oui</label>
                    </div>
                    <div class="col-5 h-100">
                        <input type="radio" class="btn-check d-none" :id="condition.name + '-no'" :name="condition.name" :checked="getProductCondition(condition.name) === false" @click="updateCondition(condition.name, false, $event)" > 
                        <label :for="condition.name + '-no'" class="btn btn-outline-primary btn-background w-100 h-100 d-flex align-items-center justify-content-center" >Non</label>
                    </div>
                    <div class="col-2 d-flex align-items-center justify-content-center px-0" style="cursor:pointer;">
                        <i @click="helperCondition = condition" class="bi bi-question-circle text-success font-size-2rem"></i>
                    </div>
                </div>
                <div v-if="condition.type == 'condition'">
                    <div class="row">
                        <div class="col-4">
                            <input type="radio" class="btn-check d-none" id="1" :name="condition.name" :checked="getProductCondition(condition.name) === 1" @click="updateCondition(condition.name, 1, $event); likeNewReminder = true">
                            <label for="1" class="btn btn-outline-primary btn-background d-flex align-items-center justify-content-center " v-html="getConditionLabel(1, productType)"></label>
                        </div>
                        <div class="col-4">
                            <input type="radio" class="btn-check d-none" id="2" :name="condition.name" :checked="getProductCondition(condition.name) === 2" @click="updateCondition(condition.name, 2, $event)">
                            <label for="2" class="btn btn-outline-primary btn-background d-flex align-items-center justify-content-center " v-html="getConditionLabel(2, productType)"></label>
                        </div>
                        <div class="col-4">
                            <input type="radio" class="btn-check d-none" id="3" :name="condition.name" :checked="getProductCondition(condition.name) === 3" @click="updateCondition(condition.name, 3, $event)">
                            <label for="3" class="btn btn-outline-primary btn-background d-flex align-items-center justify-content-center" v-html="getConditionLabel(3, productType)"></label>
                        </div>
                    </div>
                    <div class="row mt-2" style="height:50px">
                        <div class="col-5">
                            <input type="radio" class="btn-check d-none" id="4" :name="condition.name" :checked="getProductCondition(condition.name) === 4" @click="updateCondition(condition.name, 4, $event)">
                            <label for="4" class="btn btn-outline-primary btn-background d-flex align-items-center justify-content-center" v-html="getConditionLabel(4, productType)"></label>
                        </div>
                        <div class="col-5">
                            <input type="radio" class="btn-check d-none" id="5" :name="condition.name" :checked="getProductCondition(condition.name) === 5" @click="updateCondition(condition.name, 5, $event)">
                            <label for="5" class="btn btn-outline-primary btn-background d-flex align-items-center justify-content-center" v-html="getConditionLabel(5, productType)"></label>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center px-0" style="cursor:pointer;">
                            <i @click="helperCondition = condition" class="bi bi-question-circle text-success font-size-2rem"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <standard-modal v-if="alertCondition" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="close">
            <template v-slot:body>
                <p class="text-primary" v-html="$t('BASKET_CONDITION.' + alertCondition.name + '.' + productType + '.ALERT')"></p>
            </template>
        </standard-modal>
        <standard-modal v-if="helperCondition" :title="$t('BASKET_CONDITION.' + helperCondition.name + '.' + productType + '.TITLE')" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="helperCondition = null">
            <template v-slot:body>
                <p v-html="$t('BASKET_CONDITION.' + helperCondition.name + '.' + productType + '.DESCRIPTION')"></p>
                <ul v-if="helperCondition.name == 'CONDITION'">
                    <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + productType + '.1'))"></li>
                    <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + productType + '.2'))"></li>
                    <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + productType + '.3'))"></li>
                    <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + productType + '.4'))"></li>
                    <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + productType + '.5'))"></li>
                </ul>
            </template>
        </standard-modal>
        <standard-modal v-if="likeNewReminder" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="likeNewReminder = false">
            
            <template v-slot:body>
                <h4>Merci de bien vérifier que votre produit est "comme neuf".</h4>
                <ul>
                    <li>il a été acheté récemment</li>
                    <li>il n'a absolument aucune marque d'usure</li>
                </ul>
                <p>
                    L'état de votre produit est vérifié à son arrivée.
                    <br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.
                    <br/>En cas de retour, <a href="/assets/docs/dartyassurance_cgu.pdf" target="_blank">les frais de livraison vous seront facturés</a>.
                </p>
            </template>
        </standard-modal>
    </div>
</template>
