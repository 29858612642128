<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/AcceptedOfferV2.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption}, 
    mixins: [orderMixin, merchantMixin],
    created() {
      
        this.$tracking.pageView('standby', 'order', {'Order ID': this.orderId})
        this.$store.commit('setCurrentStep', 50)
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11 text-light">
                        <h3 class="text-light">En attente d'informations complémentaires</h3>
                        <p>Nous vous informons que le marchand souhaitant racheter votre produit a besoin de vous contacter afin de pouvoir effectuer son diagnostic.</p>
                        <p>La raison peut être, par exemple, la nécessité d'un code pour débloquer votre appareil.</p>
                        <p>Il vous contactera dans les prochains jours par mail ou par téléphone au {{ order.user.phone_number }}.</p>
                        <p>En cas de numéro incorrect, vous pouvez le modifier en <router-link :to="{name: 'settings'}">cliquant ici</router-link></p>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :contributionPrice="order.contribution_amount" />
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>