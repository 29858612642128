import axios from 'axios'

export default {

    postIban(iban) {

        let path = '/api/customer/user/iban'
        let body = {
            iban: iban 
        }

        return axios.post(path, body)
    }
}