<script type="text/ecmascript-6">

import { mapActions } from 'vuex'
import AuthLayout from '../../layouts/Auth.vue'

export default {
    components: {AuthLayout},
    data() {
        return {

            error: false,
            email: '',
            password: ''
        }
    },
    methods: {
        ...mapActions({
                signIn: 'signIn',
            }),
        async submitSignIn() {

                try {
    
                    await this.signIn({email: this.email, password: this.password})

                    let queryReturn = this.$route.query.return
                    if (queryReturn == null)
                        window.location = process.env.VUE_APP_MAIN_DOMAIN
                    else
                        this.$router.replace(queryReturn)
                }
                catch (error) {

                    this.error = 'Impossible de se connecter'
                }
            },
    }
}
</script>
<template>
    <auth-layout>
        <div v-if="error" class="alert alert-primary" role="alert">
            {{error}}
        </div>
        <form action="#" @submit.prevent="submitSignIn">
            <div class="form-group">
                <input required type="text" v-model="email" placeholder="Email" class="form-control w-100">
            </div>
            <div class="form-group mt-2">
                <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
            </div>
            <div class="form-group mt-2">
                <button class="btn btn-success w-100" type="submit">Se connecter</button>
            </div>
            <div class="form-group text-center mt-2">
                <router-link :to="{name: 'forgotPassword'}">J'ai oublié mon mot de passe</router-link>
            </div>
        </form>
    </auth-layout>
</template>
