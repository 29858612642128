export default {
    state: {
        merchant: null,
    },
    getters: {
        merchant: state => { return state.merchant },
    },
    mutations: {
        setMerchant (state, merchant) {
            
            state.merchant = merchant
        },
    }
}
