export default {
    state: {
        contracts: null,
        selectedContract: null
    },
    getters: {
        contracts: state => { return state.contracts },
        selectedContract: state => { return state.selectedContract },
    },
    mutations: {
        setContracts (state, contracts) {

            sessionStorage.setItem('contracts', JSON.stringify(contracts))
            state.contracts = contracts
        },
        setSelectedContract (state, contract) {

            sessionStorage.setItem('selectedContractId', contract.public_id)
            state.selectedContract = contract
        },
        unsetSelectedContract (state) {

            sessionStorage.setItem('selectedContractId', null)
            state.selectedContract = null
        },
        unsetAllContracts(state) {

            sessionStorage.setItem('selectedContractId', null)
            sessionStorage.setItem('contracts', null)
            state.contracts = null
            state.selectedContract = null
        }
    },
}
