<script type="text/ecmascript-6">
export default {
  methods: {

    emitClick() {

        this.$emit('click')
    }
  }
}
</script>

<template>
    <div class="card-body pt-2">
        <hr class="card-separator"/>
        <div class="mt-3">
            <span type="button" @click="emitClick()" class="btn btn-outline-danger justify-content-center align-items-center d-flex border-lg" style="height: 50px;">Annuler ma reprise</span>
        </div>
    </div>
</template>
