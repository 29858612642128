import orders from '../views/dashboard/Orders.vue'
import authGuard from './guards/auth.js'
import settings from '../views/dashboard/Settings.vue'

export default [
    {
        path: '/dashboard/orders',
        name: 'dashboardOrders',
        component: orders,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/settings',
        name: 'settings',
        component: settings,
        beforeEnter: authGuard.ifAuthenticated
    }
]
