<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/AcceptedOfferV2.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ProductCaption}, 
    mixins: [orderMixin, merchantMixin],
    created() {
      
        this.$store.commit('setCurrentStep', 40)
        this.$tracking.pageView('wait for validation', 'order', {'Order ID': this.orderId})
    },
}
</script>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11">
                        <h3>Votre reprise est en cours de validation</h3>
                        <p>
                            Merci pour votre reprise ! Elle sera validée d'ici 48H.
                        </p>
                        <router-link class="btn btn-success" type="button" :to="{name:'dashboardOrders'}">Mon espace client {{order.merchant.name}}</router-link>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderTotalPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" :contributionPrice="order.contribution_amount" />
                    </div>
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>
