import Vue from 'vue'
import './plugins/axios'
import Sentry from './plugins/sentry'
import Tracking from './plugins/tracking'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/index'
import VTooltip from 'v-tooltip'
import './filters/currency.js'
import './filters/date.js'
import './filters/uuid.js'
import './filters/currencyInteger.js'
import './filters/capitalize.js'
import "../public/assets/css/custom.min.css"
import 'bootstrap'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGreendid from '@greendid/vue-greendid'

Vue.config.productionTip = false
Vue.use(Tracking)
Vue.use(Sentry)
Vue.use(VTooltip)
Vue.use(VueGreendid)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY, loaderOptions: {autoHideBadge: true}})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
