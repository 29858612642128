<script type="text/ecmascript-6">
    import { mapActions } from 'vuex'
    import BasketLayout from '../../layouts/BasketV2.vue'
    import basketMixin from '@/mixins/basket'
    import merchantMixin from '@/mixins/merchant'
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import * as Sentry from "@sentry/vue";
    import faqMixin from '@/mixins/faq.js'

    export default {
        
        mixins: [basketMixin, merchantMixin, faqMixin],
        components: {BasketLayout, VuePhoneNumberInput},
        data() {
            return {

                email: '',
                password: '',
                passwordConfirm: '',
                error: null,
                wantsToRegister: true,
                passwordNotOK: false,
                firstName: '',
                lastName: '',
                phoneNumber: null,
                phoneNumberValue: null,
                isPhoneNumberValid: false,
                isCGUAccepted: false,
                isLoading: false,
                expeditionCountry: 'france'
            }
        },
        created() {

            this.$tracking.pageView('signin', 'basket', {'Basket ID': this.basketId})
        },
        methods: {
             ...mapActions({
                signIn: 'signIn',
                register: 'register'
            }),
           async submitSignIn() {

               this.$tracking.logEvent('Click signin', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'})
               this.isLoading = true
               this.error = ''

                try {
    
                    await this.signIn({email: this.email, password: this.password, basketId: this.basket.public_id})

                    this.$router.replace(this.$route.query.return)
                }
                catch (error) {

                    this.error = 'Impossible de se connecter'
                    this.$tracking.logEvent('SignIn Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                }

                this.isLoading = false
            },
            async submitRegister() {

                this.$tracking.logEvent('Click register', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'})
                this.error = ''

                if (this.expeditionCountry != 'france') {

                    return
                }

                if (!this.isCGUAccepted) {

                    this.error = 'Veuillez accepter les CGU'
                    this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})

                    return
                }

                if (this.password == this.passwordConfirm) {

                    this.isLoading = true

                    try {
                        
                        await this.register({email: this.email, password: this.password, basketId: this.basket.public_id, lastName: this.lastName, firstName: this.firstName, phoneNumber: this.phoneNumber})
                    
                        this.$router.replace(this.$route.query.return)
                    }
                    catch (error) {

                        Sentry.captureException(error)
                        if (error.response.status == 500) {

                            this.error = 'Un compte Greendid existe déjà pour cet utilisateur ' + this.merchant.name + '.'
                            this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                        }
                        else {
                            
                            let errors = error.response.data.errors
                            
                            if (Object.prototype.hasOwnProperty.call(errors, 'password') && errors.password.length > 0) {

                                this.error = 'Le mot de passe doit comporter 8 caractères ou plus'
                                this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                            }
                            else if (Object.prototype.hasOwnProperty.call(errors, 'email') && errors.email.length > 0) {

                                this.error = 'L\'email n\'est pas valide'
                                this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                            }
                            else if (Object.prototype.hasOwnProperty.call(errors, 'address.country')) {

                                this.error = 'Votre adresse doit être en France'
                                this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                            }
                        }
                    }

                    this.isLoading = false
                }
                else {

                    this.error = 'Vos mots de passe ne sont pas identiques'
                    this.$tracking.logEvent('Register Error', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket', 'Error': this.error})
                }
            },
            toggleRegister(wantsToRegister) {

                if (wantsToRegister) 
                    this.$tracking.logEvent('Toggle register', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'})
                else
                    this.$tracking.logEvent('Toggle signin', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'})

                this.error = null
                this.wantsToRegister = wantsToRegister
            },
            togglePasswordVisibility(element) {

                if (element.type == 'password')
                    element.type = 'text'
                else
                    element.type = 'password'
            },
            scrollUp() {

                window.scrollTo(0, 0)
            },
            updatePhoneNumber(data) {

                if (data.isValid)
                    this.$tracking.logEvent('Update phone number', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'})
                this.phoneNumber = data.e164
                this.isPhoneNumberValid = data.isValid
            },
        },
        computed: {

            phoneTranslations() {

                return {

                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone portable',
                    example: 'Exemple :'
                }
            },
            subdomain() {

                return window.location.hostname.split('.')[0]
            },
        },
        watch: {

            isCGUAccepted() {

                if (this.isCGUAccepted)
                    this.$tracking.logEvent('Click CGU accepted', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'});
                else
                    this.$tracking.logEvent('Click CGU not accepted', {'Basket ID': this.basketId, 'Page Name': 'register', 'Page Category': 'basket'});
            }
        }
    }
</script>
<style>
.input-tel__input,.country-selector__input {

    border-color: #dae1e7 !important;
}
</style>
<template>
    <basket-layout>
        <div v-if="this.basket">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Mon compte</h3>
                        <span>
                            Merci de <strong>créer un compte <span class="text-success">G R E E N D I D</span></strong> ou bien de <strong>vous identifier</strong> afin d'authentifier la revente de votre produit.
                        </span>
                        <div class="card shadow-lg my-4">
                            <div class="card-body mx-sm-4">
                                <ul class="nav nav-tabs justify-content-center mt-2" role="tablist">
                                    <li class="nav-item text-center col-6">
                                        <a class="nav-link px-0" :class="{active: wantsToRegister}" data-toggle="tab" v-on:click="toggleRegister(true)" style="cursor:pointer;" role="tab">Je crée un compte<br/><strong class="text-success">G R E E N D I D</strong></a>
                                    </li>
                                    <li class="nav-item text-center col-6">
                                        <a class="nav-link px-0" :class="{active: !wantsToRegister}" data-toggle="tab" v-on:click="toggleRegister(false)" style="cursor:pointer;" role="tab">J'ai déjà un compte<br/><strong class="text-success">G R E E N D I D</strong></a>
                                    </li>
                                </ul>
                                <div v-if="error" class="alert alert-danger" role="alert">
                                    {{error}}
                                </div>
                                <div class="tab-content">
                                    <div class="tab-pane" :class="{active: wantsToRegister, show: wantsToRegister, fade: !wantsToRegister}" id="signup" role="tabpanel">         
                                        <form action="#" @submit.prevent="submitRegister">
                                            <div class="ms-3 mt-4">
                                                <strong>Mon compte</strong>
                                            </div>
                                            <div class="form-group mt-2">
                                                <input class="w-100 form-control" id="email" required type="text" v-model="email" placeholder="Email">
                                            </div>
                                            <div class="form-group mt-3">
                                                <div class="input-group">
                                                    <input ref="password1" class="form-control" required type="password" v-model="password" placeholder="Mot de passe">
                                                    <div class="input-group-addon"><button tabindex="-1" @click="togglePasswordVisibility($refs.password1)" type="button" class="btn btn-icon"><i class="ci-eye" /></button></div>
                                                </div>
                                            </div>
                                            <div class="form-group mt-3">
                                                <div class="input-group">
                                                    <input ref="password2" class="form-control" required type="password" v-model="passwordConfirm" placeholder="Confirmation du mot de passe">
                                                    <div class="input-group-addon"><button tabindex="-1" @click="togglePasswordVisibility($refs.password2)" type="button" class="btn btn-icon"><i class="ci-eye" /></button></div>
                                                </div>
                                            </div>
                                            <div class="ms-3 mt-5">
                                                <strong>Mes coordonnées</strong>
                                            </div>
                                            <div class="form-group mt-2 row">
                                                <div class="col"><input class="form-control" required type="text" v-model="firstName" placeholder="Prénom"></div>
                                                <div class="col"><input class="form-control" required type="text" v-model="lastName" placeholder="Nom"></div>
                                            </div>
                                            <div class="form-group mt-3">
                                                <vue-phone-number-input :translations="phoneTranslations" v-model="phoneNumberValue" v-on:update="updatePhoneNumber" :preferred-countries="['FR', 'BE', 'DE']" :default-country-code="'FR'" />
                                            </div>
                                            <div class="form-group mt-3">
                                               J'expédie mon produit depuis ce pays : <select style="display:inline;width:auto" class="form-control" v-model="expeditionCountry"><option value="france">France métropolitaine</option><option value="autre">Autre</option></select>
                                                <div class="alert alert-danger mt-2" v-if="expeditionCountry != 'france'">
                                                    Pour finaliser votre revente depuis un autre pays que la France, merci de nous contacter par mail : <a :href="'mailto:' + getContactEmail()">{{ getContactEmail() }}</a>
                                                </div>
                                            </div>
                                            <div class="form-group mt-3">
                                                <div class="form-check">
                                                    <input class="form-check-input border-success-lg" type="checkbox" name="input-cgu" v-model="isCGUAccepted">
                                                    <label class="form-check-label">J'accepte les <a :href="'https://assets.greendid.com/docs/greendid_cgu_'+subdomain+'.pdf'" target="_blank">Conditions Générales d'Utilisation</a></label>
                                                </div>
                                            </div>
                                             <div v-if="error" class="alert alert-danger" role="alert">
                                                {{error}}
                                            </div>
                                            <div class="form-group mt-3">
                                                <button :disabled="isLoading" class="btn btn-success w-100" type="submit">
                                                    Créer mon compte
                                                    <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="tab-pane" :class="{active: !wantsToRegister, show: !wantsToRegister, fade: wantsToRegister}" id="signin" role="tabpanel">
                                        <form action="#" @submit.prevent="submitSignIn">
                                            <div class="form-group mt-3">
                                                <input required type="text" v-model="email" placeholder="Email" class="form-control w-100">
                                            </div>
                                            <div class="form-group mt-3">
                                                <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
                                            </div>
                                            <div class="form-group mt-3">
                                                <button :disabled="isLoading" class="btn btn-success w-100" type="submit">
                                                    Se connecter
                                                    <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                                </button>
                                            </div>
                                            <div class="form-group text-center mt-3">
                                                <router-link :to="{name: 'forgotPassword'}">J'ai oublié mon mot de passe</router-link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </basket-layout>
</template>

