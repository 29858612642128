import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import Home from '@/views/home/Home.vue'
import Contracts from '@/views/home/Contracts.vue'
import SignIn from '@/views/auth/SignIn.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'

import BasketRoutes from '@/router/basket.js'
import DashboardRoutes from '@/router/dashboard.js'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    ...BasketRoutes,
    ...DashboardRoutes,
    {
        path: '/',
        component: Home,
        name: 'home'
    },
    {
        path: '/contracts',
        component: Contracts,
        name: 'contracts'
    },
    {
        path: '/signIn',
        component: SignIn,
        name: 'signIn'
    },
    {
        path: '/forgotPassword',
        component: ForgotPassword,
        name: 'forgotPassword'
    },
    {
        path: '/resetPassword/:token',
        component: ResetPassword,
        name: 'resetPassword'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
