export default {

    methods: {

        loadContracts() {

            if (this.contracts == undefined) {

                let contracts = sessionStorage.getItem('contracts')
                if (contracts) {

                    this.$store.commit('setContracts',  JSON.parse(contracts))
                }
            }
        },
        loadSelectedContract() {

            if (this.selectedContract == undefined) {

                let selectedContractId = sessionStorage.getItem('selectedContractId')
                if (selectedContractId) {

                    let selectedContract = this.getContract(selectedContractId)
                    if (selectedContract) {

                        this.$store.commit('setSelectedContract',  selectedContract)
                    }
                }
            }
        },
        getContract(contractId) {

            if (this.contracts == undefined) return undefined

            return this.contracts.find((contract) => contract.public_id == contractId)
        }
    },
    computed: {

        selectedContract() {

            return this.$store.getters.selectedContract
        },
        contracts() {

            return this.$store.getters.contracts
        }
    },
    created() {

        this.loadContracts()
        this.loadSelectedContract()

        if (this.contracts == undefined || this.contracts.length == 0) {

            this.$router.push({name: 'home', query: {e: '1'}})
        }
    }
}
