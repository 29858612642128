<script type="text/ecmascript-6">
    import merchantMixin from '@/mixins/merchant'
    import mediaMixin from '@/mixins/media'

    export default {

        mixins: [merchantMixin, mediaMixin],
        computed: {

            merchantLogo() {

                return this.getLocalImage(this.merchant.asset_logo_name)
            },
        },
        created() {

            this.loadMerchantBySubdomain()
        }
    }
</script>
<template>
   <div class="container">
        <div class="row mt-4">
            <div class="offset-md-4 col-md-4 col text-center">
                <img v-if="merchant" :src="merchantLogo" :alt="this.merchant.name" width="180" />
            </div>
        </div>
        <div class="row mt-5">
            <div class="offset-md-4 col-md-4 col">
                <slot/>
            </div>
        </div>
    </div>
</template>
