import axios from 'axios'

export default {

    getCSRF() {

        var path = '/sanctum/csrf-cookie'

        return axios.get(path);
    },
    login(email, password, basketId) {

        var path = '/api/customer/login'
        var data = {
            email: email,
            password: password,
            basketId: basketId
        }

        return axios.post(path, data)
    },
    logout() {

        var path = 'api/customer/logout'

        return axios.get(path)
    },
    getCurrentUser() {

        var path = '/api/customer/user'

        return axios.get(path)
    },
    register(email, password, basketId, listingId, firstName, lastName, address, phoneNumber) {

        let basketPublicId = basketId != null ? basketId : null
        let listingPublicId = listingId != null ? listingId : null

        var path = '/api/customer/register'
        var data = {
            email: email,
            password: password,
            basketId: basketPublicId,
            listingId: listingPublicId,
            lastName: lastName,
            firstName: firstName,
            address: address,
            phoneNumber: phoneNumber,
            acceptMangoPayTerms: true
        }

        return axios.post(path, data)
    },
    forgotPassword(email) {

        var path = '/api/forgot-password'
        var data = {
            email: email
        }

        return axios.post(path, data)
    },
    resetPassword(token, email, password, passwordConfirmation) {

        var path = '/api/reset-password'
        var data = {
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation
        }

        return axios.post(path, data)
    }
}
