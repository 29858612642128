<script type="text/ecmascript-6">

import Faq from '@/mixins/faq.js'

export default {

    mixins: [Faq],
    mounted() {

        let crisp = document.createElement('script');
        let codeCrisp = 'window.$crisp=[];window.CRISP_WEBSITE_ID="'+process.env.VUE_APP_CRISP_WEBSITE_ID+'";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();'
        crisp.appendChild(document.createTextNode(codeCrisp));
        document.body.appendChild(crisp);
        
        let axeptio = document.createElement('script');
        let codeAxeptio = "window.axeptioSettings = {clientId: '"+process.env.VUE_APP_AXEPTIO_CLIENT_ID+"',};(function(d, s) {var t = d.getElementsByTagName(s)[0], e = d.createElement(s);e.async = true; e.src = '//static.axept.io/sdk.js';t.parentNode.insertBefore(e, t);})(document, 'script');"
        axeptio.appendChild(document.createTextNode(codeAxeptio));
        document.body.appendChild(axeptio);
    },
    computed: {

        subdomain() {

            return window.location.hostname.split('.')[0]
        },
        subdomainUpper() {

            let subdomain = window.location.hostname.split('.')[0]

            return subdomain.toUpperCase()
        }
    }
}
</script>
<template>
    <footer class="mt-2 mt-md-0">
        <div class="container">
            <div class="row d-flex justify-content-center pb-2">
                <div class="col-md-3 d-flex justify-content-start ps-5 mt-5">
                    <div class="widget widget-links widget-dark">
                        <ul class="widget-list">
                            <li class="mb-2"><strong>INFORMATIONS LEGALES</strong></li>
                            <li class="widget-list-item"><a class="widget-list-link" target="_blank" :href="'/assets/docs/mentions_legales_dartyassurance_com.pdf'">Mentions légales</a></li>
                            <li class="widget-list-item"><a class="widget-list-link" target="_blank" :href="'/assets/docs/politique_de_confidentialite_dartyassurance.pdf'">Politique de confidentialité</a></li>
                            <li class="widget-list-item"><a class="widget-list-link" href='javascript:openAxeptioCookies()'>Modifier vos préférences en matière de cookie</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 d-flex justify-content-start ps-5 mt-5">
                    <div class="widget widget-links widget-dark">
                        <ul class="widget-list">
                            <li class="mb-2"><strong>SERVICE DE REVENTE</strong></li>
                            <li class="widget-list-item"><a class="widget-list-link" target="_blank" :href="'/assets/docs/dartyassurance_cgu.pdf'">Conditions Générales d'Utilisation</a></li>
                            <li class="widget-list-item"><a class="widget-list-link" target="_blank" :href="getFAQURL()">Centre d'aide</a></li>
                            <li class="widget-list-item"><a class="widget-list-link" target="_blank" :href="getFAQContactURL()">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>

.widget-list {

    padding: 0;
    margin: 0;
    list-style: none;
}

.widget-list-link {
    display: block;
    transition: color .25s ease-in-out;
    font-size: .875rem;
    font-weight: 400;
    text-decoration: none;
    color: black;
}

.widget-list-item {
    margin-bottom: 0.5rem;
}

.widget-list-item:hover {
    margin-bottom: 0.5rem;
    text-decoration: underline;
}
</style>
