import axios from 'axios'

export default {

    list(merchantId, contractId, lastName) {

        let path = '/api/customer/merchants/' + merchantId + '/contracts?ln=' + lastName + '&cpid=' + contractId

        return axios.get(path)
    }
}
