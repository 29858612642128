<script type="text/ecmascript-6">
import authService from '../../services/auth'
import AuthLayout from '../../layouts/Auth.vue'

export default {

    components: {AuthLayout},
    data() {

        return {

            email: '',
            error: '',
            message: ''
        }
    },
    methods: {

        async forgotPassword() {

            this.error = ''
            this.message = ''
            
            try {

                await authService.forgotPassword(this.email)
                this.message = 'Si l\'email que vous avez renseigné est correct, vous allez recevoir un email avec les indications pour changer votre mot de passe.' 
            }
            catch(err) {

                this.error = 'Veuillez rentrer un email valide'
            }
        }
    }
}
</script>
<template>
    <auth-layout>
        <div v-if="error != ''" class="alert alert-danger" role="alert">
            {{error}}
        </div>
        <div v-if="message != ''" class="alert alert-info" role="alert">
            {{message}}
        </div>
        <form @submit.prevent="forgotPassword">
            <div class="form-group">
                <input type="email" v-model="email" placeholder="email" class="form-control w-100" />
            </div>
            <div class="form-group mt-2">
                <button type="submit" class="btn btn-success w-100">Envoyer</button>
            </div>
        </form>
    </auth-layout>
</template>
