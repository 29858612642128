<script type="text/ecmascript-6">

import { uuid } from 'vue-uuid'
export default {

    props: {
        'files': Array,
        'adStep': Number,
        'maxNbrFiles': {
            type: Number,
            default: 4
        }
    },
    data() {

        return {

            previewImages: [],
            filesData: this.files
        }
    },
    methods: {

        addFile(e) {

        let droppedFiles = e.dataTransfer != undefined ? e.dataTransfer.files : e.target.files
        
        if (droppedFiles.length > this.maxNbrFiles) {
            
            alert('Vous ne pouvez pas importer plus de ' + this.maxNbrFiles + ' photo(s)');
            
            return;
        }
        
        if (!droppedFiles) return
        ([...droppedFiles]).forEach(f => {
            
                let id = uuid.v4()
                this.filesData.push({id: id, file: f})
                this.addPreview(id, f)
                this.$emit('listingSteps')
            });
        },

        removeFile(id) {

            this.filesData = this.filesData.filter(file => {
                
                return file.id != id
            })
            this.previewImages = this.previewImages.filter(image => {

                return image.id != id
            })

            this.$emit("updateFiles", this.filesData);
            this.$emit('listingSteps')
        },
        addPreview(id, file) {

            let reader = new FileReader
            reader.onload = e => {

                this.previewImages.push({id: id, preview: e.target.result})
            }

            return reader.readAsDataURL(file)
        },
        clickOnInput() {

            this.$refs.fileInput.click();
        },
    },
}
</script>

<template>
    <div>
        <input class="d-none" id="fileInput" ref="fileInput" :multiple="maxNbrFiles > 1" type="file" @change="addFile" accept="image/*">
        <button :disabled="files.length > 3 || adStep === 4" class="btn btn-outline-primary" @click="clickOnInput">
            <span v-if="maxNbrFiles > 1">Chargez vos photos</span>
            <span v-else>Chargez votre photo</span>
        </button>
        <div class="row p-2 d-flex justify-content-center">
            <div :key="preview.id" v-for="preview in previewImages" class="photo col-6 col-lg-4 m-1 m-lg-2" :style=" { 'background-image': 'url(' + preview.preview + ')'}">
                <div class="d-flex justify-content-between m-1">
                    <i class="bi bi-camera-fill">
                    </i>
                    <span v-if="adStep < 4" @click="removeFile(preview.id)" class="bg-white text-danger px-2 delete-btn border">X</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.photo {

    height: 200px;
    border: 1px solid var(--bs-gray-400);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
}

.delete-btn {

    cursor: pointer;
}

</style>