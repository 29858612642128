<script type="text/ecmascript-6">
export default {
    data() {

        return {

            steps : [
                {
                    step: 10,
                    number: 1,
                    name: 'Déclaratif'
                },
                {
                    step: 20,
                    number: 2,
                    name: 'Offre'
                },
                {
                    step: 40,
                    number: 3,
                    name: 'Confirmation'
                },
                {
                    step: 50,
                    number: 4,
                    name: 'Expédition gratuite'
                },
            ]
        } 
    },
    props: {
        currentStep: Number
    },
    methods: {
        isCurrent(step) {

            if ((step == this.currentStep || (this.currentStep == 10 && step == 20) || (this.currentStep == 20 && step == 10)) && this.currentStep > step) 

                return true

            return false    
        },
        isActive(step) {

            if (step <= this.currentStep)
                return true

            return false
        },
        // getColor(step) {

        //     if (this.isCurrent(step)) {

        //         return '#FF6633'
        //     }
            
        //     return '#FF6633'
        // }
    }
}
</script>
<template>
    <div class="steps">
        <a class="step-item" v-for="step in steps" :key="step.step" :class="{active: isActive(step.step), current: isCurrent(step.step)}">
            <div class="step-progress">
            <span class="step-count" :class="{active: isActive(step.step), current: isCurrent(step.step)}"> {{step.number}} </span>
            </div> 
            <div class="step-label" :class="{'text-muted': !isActive(step.step) && !isCurrent(step.step), 'text-secondary': isCurrent(step.step) || !isCurrent(step.step)}">
                {{step.name}}
            </div>
        </a>
    </div>
</template>

<style scoped>

.steps {
    display: flex;
    width: 100%;
}

.step-item {
    flex-basis: 0;
    flex-grow: 1;
    transition: color .25s ease-in-out;
    text-align: center;
    text-decoration: none!important;
}

.step-count {
    position: absolute;
    top: -0.75rem;
    left: 50%;
    width: 1.625rem;
    height: 1.625rem;
    margin-left: -0.8125rem;
    border-radius: 50%;
    font-size: .875rem;
    line-height: 1.625rem;
    color:#fff;
    background-color: var(--bs-gray-600);
}

.step-label {
    padding-top: 1.5625rem;
}

.step-progress {
    position: relative;
    width: 100%;
    height: 0.25rem;
    background-color:var(--bs-gray-600);
}

.step-item:first-child .step-progress {
    border-radius: 0.125rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.step-item:last-child .step-progress {
    border-radius: 0.125rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.step-item.active .step-progress {
    color: #fff;
    background-color: var(--bs-secondary);
}
.step-item.active .step-count.active {
    color: #fff;
    background-color: var(--bs-secondary);
}

</style>
