<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<style>
    @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");
    *, a, p, button {

    font-family: var(--bs-font-sans-serif);
    }
</style>
<script type="text/ecmascript-6">
import merchantMixin from '@/mixins/merchant.js'

export default {
    mixins: [merchantMixin],
    created() {

        this.loadCssFile()
    },
    methods: {
        loadCssFile() {
            
            const style = document.createElement('link');
            style.rel = 'stylesheet';
            style.type = 'text/css';
            style.href = '/assets/css/styles/' + this.merchantCss + '.css';
            
            document.head.appendChild(style);
        } 
    }
}
</script>