import axios from 'axios'

export default {

    create(
        productId,
        merchantId,
        merchantProductId,
        merchantUserId,
        merchantOrderId,
        merchantProductImg,
        merchantOrderDate,
        merchantProductPrice,
        conditions,
        basketPublicId,
        invoiceFilename,
        brandCampaign,
        offerTemplateId,
        specsId,
        specs,
        basketType,
        paymentType,
        listingPublicId
    ) {

        var path = '/api/customer/order'
        var data = {
            productId: productId,
            merchantId: merchantId,
            merchantProductId: merchantProductId,
            merchantUserId: merchantUserId,
            merchantOrderId: merchantOrderId,
            merchantProductImg: merchantProductImg,
            merchantOrderDate: merchantOrderDate,
            merchantProductPrice: merchantProductPrice,
            conditions: conditions,
            basketPublicId: basketPublicId != null ? basketPublicId : null,
            invoiceFilename: invoiceFilename,
            brandCampaignPublicId: brandCampaign ? brandCampaign.public_id : null,
            offerTemplateId: offerTemplateId,
            specsId: specsId,
            specs: specs,
            basketType: basketType,
            paymentType: paymentType,
            listingPublicId: listingPublicId != null ? listingPublicId : null
        }

        return axios.post(path, data)
    },
    get(orderPublicId, withCanBeImmediat = false, withMerchantContract = true) {

        var path = '/api/customer/order/' + orderPublicId
        if (withCanBeImmediat === true) {

            path = path + '?c=1'
        }

        if (withMerchantContract) {

            if (path.includes('?')) {

                path = path + '&mc=1' 
            }
            else {

                path = path + '?mc=1'
            }
        }

        return axios.get(path);

    },
    getAll() {

        var path = '/api/customer/orders'

        return axios.get(path);
    },
    getDeliveryNote(orderPublicId) {

        const path = '/api/customer/order/' + orderPublicId + '/delivery'

        return axios.get(path);
    },
    createTransfer(orderPublicId, phoneNumber) {

        var path = '/api/customer/order/' + orderPublicId + '/transfer'
        var data = {
            phoneNumber: phoneNumber
        }

        return axios.post(path, data);
    },
    acceptCounterOffer(orderPublicId) {

        const path = '/api/customer/orders/' + orderPublicId + '/counterOffer/accept'

        return axios.patch(path);
    },
    refuseCounterOffer(orderPublicId) {

        const path = '/api/customer/orders/' + orderPublicId + '/counterOffer/refuse'

        return axios.patch(path);
    },
    chooseType(orderPublicId, type) {

        const path = '/api/customer/orders/' + orderPublicId
        var data = {
            type: type
        }

        return axios.patch(path, data);
    },
    createImmediatePayment(orderPublicId) {

        const path = '/api/customer/orders/' + orderPublicId + '/immediatePayment'

        return axios.post(path);
    },
    cancelOrderById(orderPublicId) {

        const path = '/api/customer/order/' + orderPublicId + '/cancel'

        return axios.patch(path);
    },
    uploadInvoice(orderPublicId, invoice) {

        const path = '/api/customer/order/' + orderPublicId + '/invoice'
        let formData = new FormData();

        formData.append('invoice', invoice)
        
        return axios.post(path, formData, {headers: {'Content-Type': 'multipart/form-data' }})
    }
}
