<script type="text/ecmascript-6">
export default {
    props: ['brand', 'description', 'img', 'itemType'],
}
</script>
<template>
    <div class="card-body row no-gutters">
        <div class="col-7">
            <h6 class="text-secondary"><strong>REPRISE</strong></h6>
            <div class="mt-3">
                <strong>{{brand}}</strong>
                <div class="small">{{description}}</div>
                <div v-if="itemType == 'product'" class="small">(ou produit équivalent)</div>    
            </div>
        </div>
        <div class="col-5 m-auto">
            <img class="img-fluid" :src="img" />
        </div>
    </div>
</template>
