import listingService from '@/services/listing'

export default {

    data() {

        return {
            isListingLoaded: false
        }
    },
    created() {

        if (this.listingId != null) {

            this.loadListing()
        }
    },
    computed: {

        listing() {

            return this.$store.getters.listing
        },
        currentStep() {

            return this.$store.getters.currentStep
        },
        listingId() {

            return this.$route.params.listingId
        }
    },
    methods: {

        setCurrentStep(val) {

            this.$store.commit('setCurrentStep', val)
        },
        async loadListing() {

            try {
                
                let response = await listingService.get(this.listingId)
                let listing = response.data

                this.$store.commit('setListing', listing)
                this.isListingLoaded = true
                await this.loadMerchant(listing.merchant_id)
              
            }
            catch (error) {
                
                console.log(error);

                if (error.response.status == 404 || error.response.status == 403 || error.response.status == 401) {

                    this.$router.push({name: 'basketError404'})
                }
            }
        },
        getConditionLabel(val, productType) {

            return this.$t('BASKET_CONDITION_LABEL.' + productType + '.' + val)
        }
    }
}
