export default {

    methods: {

        getFAQURL() {

            return 'https://darty-assurance-greendid.crisp.help/fr/';
        },

        getFAQDeliveryURL() {

            return 'https://darty-assurance-greendid.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-1nf9kkg/';
        },

        getFAQContactURL() {

            return 'https://darty-assurance-greendid.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-icwl53/';
        }
    }
}
