<script type="text/ecmascript-6">
export default {
    props: ['price', 'merchant', 'type', 'bonus', 'paymentType', 'contributionPrice'],
    methods: {
        getBrandName() {

            return this.bonus.name.toUpperCase()
        },
        getBonusAmount() {

            let value = this.bonus.bonus_amount / 100
            value = this.$options.filters.currency(value.toString())
            return value
        }
    },
}
</script>
<template>
    <div v-if="bonus" class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4 text-success">
            <div class="col-8">
                <span>
                    <strong>{{merchant.coupon_name | capitalize}}</strong><br/>
                </span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">{{price | currency}}*</h4>
            </div>
        </div>
        <div v-if="price > 0 && paymentType == 'coupon'" class="offset-1 justify-content-end mt-2">
            <p class="text-gd-faint font-size-xxs">
                En {{merchant.coupon_name}} {{merchant.name}}
                valable <strong>{{merchant.coupon_validity_period}} {{merchant.coupon_validity_products}} {{merchant.coupon_validity_places}}</strong>.
            </p>
        </div>
        <div v-else-if="price > 0 && paymentType == 'cash'" class="offset-1 justify-content-end mt-2">
            <p class="text-gd-faint font-size-xxs">En cash</p>
        </div>
        <hr class="card-separator"/>
        <div class="row mt-4 text-success">
            <div class="col-8">
                <span>
                    <strong>Bonus {{getBrandName()}}</strong><br/>
                </span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">+{{getBonusAmount()}}*</h4>
            </div>
        </div>
        <div class="offset-1 justify-content-end mt-2">
            <p class="text-gd-faint font-size-xxs">
                <strong>Sous la forme de code de réduction {{merchant.name}}</strong>
                <strong v-html="bonus.legal_application_label"></strong>
            </p>
        </div>
    </div>

    <div v-else class="card-body pb-2">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-8">
                <span v-if="paymentType == 'coupon'">
                    <strong>{{merchant.coupon_name | capitalize}}</strong>
                </span>
                <span v-if="paymentType == 'cash'"><strong>En cash</strong></span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">{{price | currency}}*</h4><small v-if="contributionPrice && contributionPrice > 0" class="font-size-xxs text-success">dont {{ contributionPrice | currency}} de complément</small>
            </div>
        </div>
        <div class="row mt-2">
            <small v-if="paymentType == 'coupon'" class="offset-2 col-10 text-end" style="font-size:0.8rem">
                En {{merchant.coupon_name}} {{merchant.name}} valable <strong>{{merchant.coupon_validity_period}}</strong> sur <strong>{{merchant.coupon_validity_products}}</strong> {{merchant.coupon_validity_places}}
            </small>
        </div>
    </div>
</template>
