<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import orderService from '@/services/order'
import orderHistoryService from '@/services/orderHistory'
import basketService from '@/services/basket'
import media from '@/mixins/media'
import OrderStatus from '@/components/dashboard/OrderStatus.vue'

export default {
    components: {DashboardLayout, OrderStatus},
    mixins: [media],
    data() {

        return {
            orders: [],
            tabs: {
                running: true,
                done: false,
            },
            orderToRemove: null,
            expectedOrders: []
        }
    },
    created() {

        this.$tracking.pageView('orders', 'dashboard')
        this.loadOrders()
        this.loadExpectedOrder()
        this.loadTab()
    },
    methods: {

        async loadOrders() {

            let response = await orderService.getAll()
            this.orders = response.data
        },
        async loadExpectedOrder() {

            let response = await orderHistoryService.list()
            this.expectedOrders = response.data
        },
        toggleTabs(activeTab) {

            for (let tabName in this.tabs) {

                if (tabName == activeTab) {

                    this.tabs[tabName] = true
                }
                else {

                    this.tabs[tabName] = false
                }
            }
        },
        loadTab() {

            if (this.$route.hash != '') {

                this.toggleTabs(this.$route.hash.replace('#', ''))
            }
        },
        async removeExpectedOrder(expectedOrderId) {

            await orderHistoryService.delete(expectedOrderId)
            let index = this.expectedOrders.findIndex(element => element.id == expectedOrderId)
            this.expectedOrders.splice(index, 1)
            this.orderToRemove = null
        },
        async resell(order) {

            try {

                let response = await basketService.create(order.merchant_user_id, '0', order.merchant_id, order.merchant_order_id, order.label, order.merchant_product_img, order.merchant_order_date, order.merchant_product_price)

                window.location = response.data.url
            }
            catch (error) {

                console.log(error)
            }
        },
        getOrderLink(order) {

            return {'name': 'orderConfirm', 'params': {'id': order.public_id}}
        },
        isButton(order) {

            return [1,2,6,9].includes(order.order_status_id)
        },
        getTotalAmount(order) {

            let total = Number(order.price) + Number(order.bonus_amount) + Number(order.contribution_amount)

            return total.toFixed(2)
        }

    },
    computed: {

        runningOrders() {

            return this.orders.filter(order => [1,2,3,4,6,7,8,9,11].includes(order.order_status_id))
        },
        doneOrders() {

            return this.orders.filter(order => [5, 10].includes(order.order_status_id))
        },
        couponsDone() {

            let total = 0
            this.doneOrders.forEach(order => total = total + Number(order.price) + Number(order.bonus_amount))

            return total.toFixed(2)
        },
        couponsRunning() {

            let total = 0
            this.runningOrders.forEach(order => total = total + Number(order.price) + Number(order.bonus_amount))

            return total.toFixed(2)
        },
        couponsExpected() {

            let total = 0
            this.expectedOrdersFiltered.forEach(function(order) {

                if (order.best_product_offer !== undefined) {

                    total = total + Number(order.best_product_offer.price)
                }
            })

            return total.toFixed(2)
        },
        isRunningTabActive() {

            return this.tabs.running
        },
        isDoneTabActive() {

            return this.tabs.done
        },
        isResellTabActive() {

            return this.tabs.resell
        },
        expectedOrdersFiltered() {

            return this.expectedOrders.filter(expectedOrder => this.orders.find(order => order.label == expectedOrder.label && order.order_status_id != 20) == null)
        }
    }
}
</script>
<style scoped>

a[role=tab] {

    text-decoration: none;
}

</style>
<template>
    <dashboard-layout>
        <h3>Listes des ventes</h3>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a href="#running" @click="toggleTabs('running')" :class="{'text-tertiary active': isRunningTabActive}" class="nav-link text-dark" role="tab">Reprises en cours</a>
            </li>
            <li class="nav-item">
                <a href="#done" @click="toggleTabs('done')" :class="{'text-tertiary active': isDoneTabActive}" class="nav-link text-dark" role="tab">Reprises OK</a>
            </li>
        </ul>
        <div class="row mx-n2 pt-2">
            <div class="col-md-3 col-sm-6 px-2 mb-4">
                <a href="#running" @click="toggleTabs('running')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center text-muted border border-muted" :class="{'border-tertiary': isRunningTabActive}">
                        <h3 class="font-size-sm">Reprises en cours</h3>
                        <p class="h2 mb-2 text-tertiary">{{couponsRunning|currency}}</p>
                        <p class="font-size-xs" v-if="!isRunningTabActive">Voir le détail</p>
                    </div>
                </a>
            </div>
            <div class="col-md-3 col-sm-6 px-2 mb-4">
                <a href="#done" @click="toggleTabs('done')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center text-muted border border-muted" :class="{'border-tertiary': isDoneTabActive}">
                        <h3 class="font-size-sm">Reprises OK</h3>
                        <p class="h2 mb-2 text-tertiary">{{couponsDone|currency}}</p>
                        <p class="font-size-xs" v-if="!isDoneTabActive">Voir le détail</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="tab-content">
            <div class="tab-pane fade" :class="{show: isRunningTabActive, active: isRunningTabActive}" id="running" role="tabpanel">
                <div class="table-responsive font-size-sm">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Référence</th>
                                <th>Catégorie</th>
                                <th>Marque</th>
                                <th>Produit</th>
                                <th>Enseigne</th>
                                <th>Date achat</th>
                                <th>Prix achat</th>
                                <th>Date vente</th>
                                <th>Prix vente</th>
                                <th>Statut</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white" v-for="order in runningOrders" :key="order.public_id">
                                <td class="align-middle" data-toggle="tooltip" data-placement="top" :title="order.public_id">
                                    <router-link :to="'/order/' + order.public_id">{{order.public_id}}</router-link>
                                </td>
                                <td class="align-middle">{{order.category.name}}</td>
                                <td class="align-middle">{{order.brand_name}}</td>
                                <td class="align-middle">{{order.label}}</td>
                                <td class="align-middle text-center"><a target="_blank" :href="order.merchant.orders_history_url"><img style="max-width:30px;" :src="getMerchantAsset(order.merchant_id, order.merchant.asset_logo_name)" /></a></td>
                                <td class="align-middle text-right">{{order.merchant_order_date}}</td>
                                <td class="align-middle text-right text-nowrap">{{order.merchant_product_price}}</td>
                                <td class="align-middle text-right">{{order.created_at|date}}</td>
                                <td class="text-success align-middle text-right"><strong>{{getTotalAmount(order)|currency}}</strong></td>
                                <td class="align-middle text-center">
                                    <router-link v-if="isButton(order)" :to="getOrderLink(order)">
                                        <order-status :orderStatusId="order.order_status_id" />
                                    </router-link>
                                    <order-status v-if="!isButton(order)" :orderStatusId="order.order_status_id" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="tab-pane fade" :class="{show: isDoneTabActive, active: isDoneTabActive}" id="done" role="tabpanel">
                <div class="table-responsive font-size-sm">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Référence</th>
                                <th>Catégorie</th>
                                <th>Marque</th>
                                <th>Produit</th>
                                <th>Date vente</th>
                                <th>Prix vente</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white" v-for="order in doneOrders" :key="order.public_id">
                                <td class="align-middle" data-toggle="tooltip" data-placement="top" :title="order.public_id">{{order.public_id}}</td>
                                <td class="align-middle">{{order.category.name}}</td>
                                <td class="align-middle">{{order.brand_name}}</td>
                                <td class="align-middle">{{order.label}}</td>
                                <td class="align-middle text-right">{{order.created_at|date}}</td>
                                <td class="text-success align-middle text-right text-nowrap"><strong>{{getTotalAmount(order)|currency}}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="orderToRemove" class="modal" tabindex="-1" role="dialog" id="modalOrderToRemove">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Je ne le possède plus</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Fermer" @click="orderToRemove = null">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="font-size-sm">Veuillez confirmer que vous ne possédez plus le produit : <br /><strong>{{orderToRemove.label}}</strong></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="orderToRemove = null">Fermer</button>
                        <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" @click="removeExpectedOrder(orderToRemove.id)">Supprimer</button>
                    </div>
                </div>
            </div>
        </div>
    </dashboard-layout>
</template>


