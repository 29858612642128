<script type="text/ecmascript-6">

import { mapActions } from 'vuex'

export default {
    
    methods: {
        ...mapActions({
                signOut: 'signOut',
            }),
        logout() {

            this.signOut()
            this.$router.push({name: 'signIn'})
        }
    }
}
</script>
<template>

    <header class="navbar navbar-expand-lg navbar-light box-shadow-sm">
        <div class="container">
            <!-- Brand -->
            <a class="navbar-brand d-none d-lg-block mr-3 order-lg-1" href="/" style="min-width: 7rem;">
                <img width="142" src="/assets/img/logo-greendid.webp" alt="Greendid"/>
            </a>
            <a class="navbar-brand d-lg-none mr-2 order-lg-1" href="/" style="min-width: 2.125rem;">
                <img width="34" src="/assets/img/logo-greendid.webp" alt="Greendid"/>
            </a>

            <!-- Toolbar -->
            <div class="navbar-toolbar d-flex align-items-center order-lg-3">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <small>Bonjour, {{$store.getters.user.first_name}}</small>
                                <br/>Mon compte
                            </a>
                            <ul class="dropdown-menu">
                                <li><router-link :to="{name: 'settings'}" class="dropdown-item">Paramètres</router-link></li>
                                <li><a class="dropdown-item" href="#" @click="logout">Se déconnecter</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Collapsible menu -->
            <div class="collapse navbar-collapse mr-auto order-lg-2" id="navbarCollapse">
                <hr class="d-lg-none mt-3 mb-2">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link text-tertiary" href="/dashboard/orders">Liste des ventes</a></li>
                </ul>
            </div>
        </div>
    </header>
</template>
