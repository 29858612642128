<script type="text/ecmascript-6">
export default {
    props: {
        totalPrice: Number,
        price: Number,
        insurancePrice: Number,
        percentPrice: Number
    },
}
</script>
<template>
    <div class="card-body pb-2">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-6">
                <span>Prix de revente&nbsp;:</span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-tertiary">{{price | currency}}</h4>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-6">
                <span>Complément Darty&nbsp;:</span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-tertiary">{{insurancePrice | currency}}</h4>
            </div>
        </div>
        <hr style="border-top: dotted 1px #000;"/>
        <div class="row mt-4">
            <div class="col-6">
                <span>Total reprise</span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-primary">{{totalPrice | currency}}*</h4>
            </div>
        </div>
        <div class="row text-end" v-if="percentPrice">
            <small>Soit <strong>{{percentPrice}}%</strong> du prix inital</small>
        </div>
        <div class="row mt-4">
            <small style="font-size:0.8rem" class="offset-2 col-10 text-end">En Carte Cadeaux Darty valable 3 mois dans tous les magasins Darty (hors site web darty.fr)</small>
        </div>
    </div>
</template>
