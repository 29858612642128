import axios from 'axios'

export default {

    getMerchant(merchantId) {

        var path = '/api/customer/merchant/' + merchantId

        return axios.get(path)
    },
    getMerchantBySubdomain(subdomain) {

        var path = '/api/customer/merchant?s=' + subdomain

        return axios.get(path)
    }
}
