<script type="text/ecmascript-6">
export default {
    
}
</script>
<template>
    <div class="row mt-3 mt-md-5 text-success text-md-start text-center bg-primary">
        <div class="offset-md-0 col-md-12 mt-md-4 mb-md-2">
            <div class="row">
                <div class="mt-2 mt-md-0 col-md-1 d-md-flex justify-content-end px-0">
                    <img class="mb-2" style="width:50px;height:50px" src="/assets/img/reinsurance-fast-resell.png" /> 
                </div>
                <div class="mt-2 mt-md-0 col-md-2 text-light">
                    <p class="small">
                        <strong>Revente en 2 minutes</strong>
                        <span class="mt-1 text-gd-faint"><br>Pas d'annonce à créer<br>ni de prix à négocier</span>
                    </p>
                </div>
                <div class="mt-2 mt-md-0 col-md-1 d-md-flex justify-content-end px-0">
                    <img class="mb-2" style="width:50px;height:50px" src="/assets/img/reinsurance-best-offer.png" />
                </div>
                <div class="mt-2 mt-md-0 col-md-2 text-light">
                    <p class="small">
                        <strong>Prix de reprise garanti</strong>
                        <span class="mt-1 text-gd-faint"><br>Grâce à votre contrat d’assurance</span>
                    </p>
                </div>
                <div class="mt-2 mt-md-0 col-md-1 d-md-flex justify-content-end px-0">
                    <img class="mb-2" style="width:50px;height:50px" src="/assets/img/reinsurance-free-delivery.png" />
                </div>
                <div class="mt-2 mt-md-0 col-md-2 text-light">
                    <p class="small">
                        <strong>Frais d'expéditions offerts</strong>
                        <span class="mt-1 text-gd-faint"><br>Etiquette pour l’expédition<br>de votre produit fournie</span>
                    </p>
                </div>
                <div class="mt-2 mt-md-0 col-md-1 d-md-flex justify-content-end px-0">
                    <img class="mb-2" style="width:50px;height:50px" src="/assets/img/reinsurance-clean-data.png" />
                </div>
                <div class="mt-2 mt-md-0 col-md-2 text-light">
                    <p class="small">
                        <strong>Sérénité garantie</strong>
                        <span class="mt-1 text-gd-faint"><br>Accompagnement du début<br>à la fin de la revente</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>