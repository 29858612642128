import Basket from '@/views/basket/Basket.vue'
import ListingAcceptOffer from '@/views/basket/ListingAcceptOffer.vue'
import BasketAcceptOffer from '@/views/basket/BasketAcceptOffer.vue'
import Transfer from '@/views/basket/Transfer.vue'
import Kyc from '@/views/basket/Kyc.vue'
import Invoice from '@/views/basket/Invoice.vue'
import Error404 from '@/views/basket/Error404.vue'
import authGuard from './guards/auth.js'
import SignIn from '@/views/basket/SignIn.vue'
import ListingSignIn from '@/views/basket/ListingSignIn.vue'
import Delivery from '@/views/basket/Delivery.vue'
import Confirm from '@/views/basket/Confirm.vue'
import Refused from '@/views/basket/Refused.vue'
import CounterOffer from '@/views/basket/CounterOffer.vue'
import Accepted from '@/views/basket/Accepted.vue'
import CounterOfferAccepted from '@/views/basket/CounterOfferAccepted.vue'
import CounterOfferRefused from '@/views/basket/CounterOfferRefused.vue'
import Sent from '@/views/basket/Sent.vue'
import ToValidate from '@/views/basket/ToValidate.vue'
import Expired from '@/views/basket/Expired.vue'
import StandBy from '@/views/basket/StandBy.vue'
import SearchForm from '@/views/basket/SearchForm.vue'
import IbanForm from '@/views/basket/IbanForm.vue'
import Listing from '@/views/basket/Listing.vue'
import WaitForValidation from '@/views/basket/WaitForValidation.vue'

export default [
    {
        path: '/basket/:id',
        name: 'basketInfo',
        component: Basket
    },
    {
        path: '/basket/:id/acceptOffer',
        name: 'basketAcceptOffer',
        component: BasketAcceptOffer
    },
    {
        path: '/listing/:listingId/acceptOffer',
        name: 'listingAcceptOffer',
        component: ListingAcceptOffer
    },
    {
        path: '/order/:id/transfer',
        name: 'orderTransfer',
        component: Transfer,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/kyc',
        name: 'orderKyc',
        component: Kyc,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/invoice',
        name: 'orderInvoice',
        component: Invoice,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/delivery',
        name: 'orderDelivery',
        component: Delivery,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/error404',
        name: 'basketError404',
        component: Error404
    },
    {
        path: '/basket/:id/signin',
        name: 'basketSignIn',
        component: SignIn
    },
    {
        path: '/listing/:id/signin',
        name: 'listingSignIn',
        component: ListingSignIn
    },
    {
        path: '/order/:id/confirm',
        name: 'orderConfirm',
        component: Confirm,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id',
        name: 'order',
        redirect: {name: 'orderConfirm'},
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/return',
        name: 'orderRefused',
        component: Refused,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/counterOffer',
        name: 'orderCounterOffer',
        component: CounterOffer,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/accepted',
        name: 'orderAccepted',
        component: Accepted,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/counterOfferAccepted',
        name: 'orderCounterOfferAccepted',
        component: CounterOfferAccepted,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/counterOfferRefused',
        name: 'orderCounterOfferRefused',
        component: CounterOfferRefused,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/sent',
        name: 'orderSent',
        component: Sent,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/toValidate',
        name: 'orderToValidate',
        component: ToValidate,
        beforeEnter: authGuard.ifAuthenticated
    },

    {
        path: '/order/:id/expired',
        name: 'orderExpired',
        component: Expired,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/standBy',
        name: 'orderStandBy',
        component: StandBy,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/waitForValidation',
        name: 'orderWaitForValidation',
        component: WaitForValidation,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/searchForm',
        name: 'searchForm',
        component: SearchForm,
    },
    {
        path: '/order/:id/ibanForm',
        name: 'ibanForm',
        component: IbanForm,
    },
    {
        path: '/listing/:listingId',
        name: 'listingByPublicId',
        component: Listing,
    },
    {
        path: '/listing',
        name: 'listing',
        component: Listing,
    }
]
