<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import merchantMixin from '@/mixins/merchant'
import wordingMixin from '@/mixins/wording'
import contractMixin from '@/mixins/contract'
import contractService from '@/services/contract'

export default {

    components: {BasketLayout},
    mixins: [merchantMixin, wordingMixin, contractMixin],
    data() {

        return {

            contractId: '',
            lastName: '',
            isLoading: false,
            error: ''
        }
    },
    created() {

        this.loadMerchantBySubdomain()
        this.$store.commit('unsetAllContracts')

        if (this.$route.query.e == 1) {

            this.error = 'Il n\'y a pas de contrat lié à ces informations.'
        }

        this.$tracking.pageView('home', 'home')
    },
    methods: {

        async login() {

            let contractId = this.contractId
            let merchantId = this.merchantId
            let lastName = this.lastName
            this.error = ''

            this.isLoading = true
            try {
                
                let response = await contractService.list(merchantId, contractId, lastName)

                this.$store.commit('setContracts', response.data)
            }
            catch (error) {

                this.isLoading = false
                this.error = 'Une erreur est survenue'
                console.log(error)
            }

            this.$router.push({name: 'contracts'})
        },
        fillContract() {

            if (this.contractId.length == 0) {

                this.contractId = this.getWording('CONTRACT_PREFIX')
            }
        },
    },
    computed: {

        isFormEmpty() {
         
            return this.contractId.length == 0 || this.lastName.length == 0
        },
    },
}

</script>

<template>
    <basket-layout>
        <div v-if="merchant" class="mt-1 mt-lg-4 offset-0 col offset-lg-1 col-lg-8">
            <h1 class="text-primary">Portail Reprise - {{ getWording('PLATFORM_NAME') }}</h1>
            <p>Merci d'indiquer votre numéro de contrat {{ getWording('PLATFORM_NAME') }} ainsi que votre nom de famille.</p>
            <div class="card px-1 px-lg-4">
                <div class="card-body">
                    <p class="card-title text-center">
                        Mon contrat<br/>
                        <strong class="text-primary">{{ getWording('PLATFORM_NAME') }}</strong>
                    </p>
                    <hr class="row text-tertiary" />
                    <div>
                        <div class="row mb-3">
                            <input :disabled="isLoading" @click="fillContract" v-model="contractId" class="form-control" type="text" placeholder="Numéro de contrat" />
                        </div>
                        <div class="row mb-3">
                            <input :disabled="isLoading" v-model="lastName" class="form-control" type="text" placeholder="Nom de famille" />
                        </div>
                        <div v-if="error" class="row mb-3 alert alert-danger" role="alert">
                            {{ error }}
                        </div>
                        <div class="row mb-3">
                            <button :disabled="isLoading || isFormEmpty" @click="login" class="btn btn-primary">
                                <div v-if="isLoading" class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Se connecter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </basket-layout>
</template>
