<script type="text/ecmascript-6">

import Layout from '@/layouts/BasketV2.vue'
import merchantMixin from '@/mixins/merchant'

export default {
    components: {Layout},
    mixins: [merchantMixin],
    created() {

        this.$tracking.pageView('expired', 'order')
    }
}
</script>

<template>
    <layout :withBreadCrumbs="false">
        <div class="mt-4 text-center">
            <h3 class="text-light">Votre revente a expiré</h3>
            <p class="mt-4 text-light" v-if="merchant != undefined">    
                Pas de soucis !
                <br/>Revendez votre produit à partir de votre <br/><a href="https://www.rueducommerce.fr/account/tracking_my_resales.html">liste de reventes potentielles sur {{order.merchant.name}}</a>
            </p>
        </div>
    </layout>
</template>
