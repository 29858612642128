<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import merchantMixin from '@/mixins/merchant'
import FaqMixin from '@/mixins/faq'
import validator from 'validator';
import userServices from '../../services/user';

export default {

    mixins: [merchantMixin, FaqMixin],
    components: {BasketLayout}, 
    data() {

        return {

            formData : {
                
                iban : ''
            },
            isFormDataCorrect : false,
            isAlreadyStocked : localStorage.isAlreadyStocked,
            orderId: this.$route.params.id
        }
    },
    created() {
        
        this.$tracking.pageView('IBAN Form', 'IBAN Form')
        this.$store.commit('setCurrentStep', 50)
    },
    methods: {

        async postIban() {

            await userServices.postIban(this.formData.iban)
            localStorage.isAlreadyStocked = true

            this.$tracking.logEvent("Click Validate IBAN", {'Page Name': 'IBAN Form', 'Placeholder':'IBAN Button'})
            this.$router.push({name: 'orderConfirm', params: {id: this.orderId}, query: {f: 1}})
        },
        checkIban() {

            return validator.isIBAN(this.formData.iban)
        },
    },
}
</script>

<template>
    <basket-layout>
        <div class="row mt-3 mt-md-5 d-md-flex">
            <div class="m-3 m-md-0 col-md-10 ms-md-5">
                <div class="col-md-6">
                    <h3>Renseignez votre IBAN</h3>
                    <div class="d-flex flex-row align-items-end justify-content-between">
                        <p>Afin de recevoir le virement sur votre compte, veuillez renseigner votre IBAN. </p>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <div class="form-group my-1">                        
                        <input class="form-control" v-model="formData.iban" type="text" placeholder="IBAN">
                    </div>
                    <button @click="postIban()" type="button" :disabled="!checkIban() || isAlreadyStocked" class="btn btn-success mt-3">
                        <span v-if="isAlreadyStocked">Votre IBAN est en cours de vérification</span>
                        <span v-else>Je valide mes informations</span>
                    </button><br>
                    <small v-if="!checkIban() && formData.iban != ''" class="text-danger">L'IBAN renseigné n'est pas valide</small>
                </div>
            </div>
        </div>
        <div class="card mt-4 ms-md-5">
            <div class="card-body row pb-2">
                <div class="col-1">
                    <i class="bi bi-question-circle text-success font-size-2rem"></i>
                </div>
                <div class="col ps-0">
                    <ul>
                        <li>L'IBAN est l'identifiant de votre compte bancaire</li>
                        <li>Il est indiqué sur votre RIB et est nécessaire pour percevoir un virement.</li>
                    </ul>
                </div>
            </div>
        </div>
    </basket-layout>
</template>