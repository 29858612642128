export default {

    methods: {

        getWording(name) {

            if (this.merchantId == undefined) return ''

            return this.$t('GENERAL.' + this.merchantId + '.' + name)
        }
    }
}
