<script type="text/ecmascript-6">

import listingMixin from '@/mixins/listing'
import BasketLayout from '@/layouts/BasketV2.vue'
import merchantMixin from '@/mixins/merchant'
import Vouch from '@/components/basket/Vouch.vue'
import orderService from '@/services/order'

export default {

    mixins: [merchantMixin, listingMixin],
    components: {BasketLayout, Vouch}, 
    data() {

        return {

            conditionAccepted: true,
            offerValidated: false,
            needToRefresh: false,
            isLoading: false,
            orderRunning: false,
            selectedCampaign: null,
            
        }
    },
    created() {
        
        this.setCurrentStep(30)
    },
    watch: {
        offerValidated(val) {

            if (val == true && this.$store.getters.autoAcceptOffer == true) {

                this.acceptOffer()
            }
        },
        isListingLoaded() {

            if (this.listing != undefined)
                this.offerValidated = true
        }
    },
    methods: {
    
        async acceptOffer() {

            this.isLoading = true
            try {

                console.log('toto')
                let conditions = '{"CONDITIONS": ' + this.listing.product_condition_id + '}'

                let response = await orderService.create(
                    null, 
                    this.listing.merchant_id, 
                    this.listing.merchant_product_id,
                    this.listing.merchant_user_id,
                    this.listing.merchant_order_id,
                    this.listing.merchant_product_img,
                    this.listing.merchant_order_date,
                    this.listing.merchant_product_price,
                    conditions,
                    null,
                    this.listing.invoice_filename,
                    this.selectedCampaign != undefined ? this.selectedCampaign : this.$store.getters.selectedCampaign,
                    this.listing.offer_template_id != undefined ? this.listing.offer_template.id : null, 
                    this.listing.offer_specs_id != undefined ? this.listing.offer_specs.id : null,
                    this.listing.offer_specs != undefined ? this.listing.offer_specs.specs : null,
                    this.listing.type, 
                    'coupon',
                    this.listing.public_id
                )

                // this.$tracking.logEvent('Offer Accepted', {'Basket ID': this.basketId, 'Price': this.basketOffer.price,'Auto Accepted': this.$store.getters.autoAcceptOffer})
                this.$store.commit('setAutoAcceptOffer', false)
                this.$router.push({name: 'orderKyc', params:{id: response.data.public_id}})
            }
            catch (error) {

                if (error.response.status == 401) {

                    this.$store.commit('setAutoAcceptOffer', true)
                    this.$router.push({name: 'listingSignIn', params:{id: this.listing.public_id}, query:{return: this.$route.fullPath}})
                }
                if (error.response.status == 500 && error.response.data.message) {

                    this.orderRunning = true
                }
            }
            this.isLoading = false
        },
    },
    computed: {
        
        listingPublicId() {

           return this.$route.params.id
        }
    },
}
</script>
<template>
    <basket-layout>
        <div v-if="this.listing">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-10 col-lg-7 ms-md-5">
                    <div class="col-md-11">
                        <h3>Offre de reprise</h3>
                        <p>Validez votre offre de reprise pour votre produit.</p>
                        <vouch :maxBestOffer="{price: listing.price / 100, price_contribution: listing.contribution_amount / 100}" :hasBonus="false" :merchant="this.merchant" />
                        <div class="row">
                            <div class="mb-2 pe-md-0 pe-3 col-md-7 col">
                                <button :disabled="(offerValidated && $store.getters.autoAcceptOffer) || isLoading" class="btn btn-success shadow-lg text-white w-100 text-center my-4" @click="acceptOffer()">
                                    J'ACCEPTE L'OFFRE 
                                    <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </basket-layout>
</template>