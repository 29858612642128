<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/CounteredOffer.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption}, 
    mixins: [orderMixin, merchantMixin],
    created() {
      
        this.$tracking.pageView('counter offer accepted', 'order', {'Order ID': this.orderId})
    },
    computed: {
        isStatusAccepted() {

            return this.isOrderLoaded && this.order.order_status_id == 5 && this.order.accepted_counter_offer != undefined ? true : false
        }
    }
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isStatusAccepted">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-11 text-light">
                        <h3 class="text-light">Félicitations !</h3>
                        <p v-if="order.coupon_type == 'POSTPONED'">
                            Vous avez accepté la contre-offre de reprise de votre produit.<br/>
                            Vous allez bientôt recevoir <span class="text-success">votre argent dans les prochains jours</span>.
                        </p>
                        <p v-if="order.coupon_type == 'IMMEDIAT'">
                            Conformément à nos CGU vous allez être débité de {{(order.accepted_counter_offer.from_price - order.accepted_counter_offer.to_price) | currencyInteger }}
                        </p>
                        <a class="btn btn-success" type="button" :href="order.merchant.orders_history_url">Mon Espace Client {{order.merchant.name}}</a>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :counter="order.accepted_counter_offer" :contributionPrice="order.contribution_amount" />
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>
