<script type="text/ecmascript-6">
export default {
    props: {
        'basket': Object,
        'bonus': {
            type: Object,
            default: null
        }
    },
    methods: {

        getTotalAmount() {

            let total = Number(this.basket.price || this.basket.product_offer.price || this.basket.offer_specs.price) + Number(this.realBonus.bonus_amount / 100)
            return total
        }
    },
    computed: {

        realBonus() {

            if (this.bonus != undefined) {

                return this.bonus
            }

            if (this.basket.brand_campaign != undefined) {

                return this.basket.brand_campaign
            }

            return null
        }
    }
}
</script>
<template>
    <div class="card-body row no-gutters">
        <h6 class="text-secondary mb-0 d-flex justify-content-between">
            <strong>REPRISE</strong>
            <span v-if="this.realBonus" class="ms-3"><strong>{{getTotalAmount()}}€</strong></span>
        </h6>
        <div class="col-7">
            <div v-if="basket.attached_to === 'product'">
                <div class="mt-3">
                    <strong>{{basket.product.brand.name}}</strong>
                    <div class="small">{{basket.product.name}}</div>   
                </div>
                <table v-if="basket.type === 'order_history'" class="table-borderless w-100 mt-4 font-size-xxs">
                    <tbody>
                        <tr>
                            <td class="pr-3"><strong>Prix d'achat</strong></td>
                            <td>{{basket.merchant_product_price}} €</td>
                        </tr>
                        <tr>
                            <td class="pr-3"><strong>Date d'achat</strong></td>
                            <td>{{basket.merchant_order_date | date}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else-if="basket.attached_to === 'specs'">
                <div class="mt-3">
                    <div class="small">{{basket.offer_specs.label.replace('|', '')}}</div>
                </div>
            </div>
            <div v-else-if="basket.attached_to === 'offer_template'">
                <div class="mt-3">
                    <strong>{{basket.offer_template.displayed_brand}}</strong>
                    <div class="small">{{basket.offer_template.label.replace('|', '')}}</div>
                </div>
            </div>
            <div v-else-if="basket.attached_to === 'listing'">
                <div class="mt-3">
                    <strong>{{basket.brand_name}}</strong>
                    <div class="small">{{basket.label.replace('|', '')}}</div>
                </div>
            </div>
        </div>
        <div class="col-5 m-auto">
            <img class="img-fluid" :src="basket.merchant_product_img" />
        </div>
    </div>
</template>
