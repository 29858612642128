import basketService from '@/services/basket'
import merchantMixin from '@/mixins/merchant'

export default {

    mixins: {merchantMixin},
    data() {

        return {
            isBasketLoaded: false
        }
    },
    created() {

        if (this.$route.params.id)
            this.loadBasket(this.$route.params.id)
    },
    computed: {

        basket() {

            return this.$store.getters.basket
        },
        currentStep() {

            return this.$store.getters.currentStep
        },
        basketId() {

            return this.$route.params.id
        }
    },
    methods: {

        setCurrentStep(val) {

            this.$store.commit('setCurrentStep', val)
        },
        async loadBasket(basketId) {

            try {
                
                let response = await basketService.getBasket(basketId)
                let basket = response.data

                if (basket.order_public_id != null) {

                    this.$router.push({name: 'orderConfirm', params:{id: basket.order_public_id}})
                }
                else {
                    
                    this.$store.commit('setBasket', basket)
                    this.isBasketLoaded = true
                    await this.loadMerchant(basket.merchant_id)
                }
            }
            catch (error) {

                // if (error.response.status == 404 || error.response.status == 403 || error.response.status == 401) {

                //     this.$router.push({name: 'basketError404'})
                // }
            }
        },
        getConditionLabel(val, productType) {

            return this.$t('BASKET_CONDITION_LABEL.' + productType + '.' + val)
        }
    }
}
